import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { SimpleCheckbox } from '../../../components';
import 'moment/locale/fr';

class StatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: {}
    };
  }

  componentWillMount() {
    let { status } = this.props;
    this.setState(ps => ({ ...ps, status }));
  }

  render() {
    let { status } = this.state,
      { statuses, t } = this.props;

    return (
      <Modal
        size={'md'}
        isOpen={this.props.show}
        toggle={() => this.props.onCancel()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.props.onCancel()}
        >
          <Trans>Change Status</Trans>
        </ModalHeader>
        <ModalBody>
          {statuses
            ? statuses.map((st, key) => (
                <Row key={key}>
                  <Col>
                    <SimpleCheckbox
                      label={t(st.toString())}
                      inputProps={{
                        value: st,
                        checked: st === status,
                        onChange: () => this.setState({ status: st })
                      }}
                    />
                  </Col>
                </Row>
              ))
            : null}
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            className="text-left"
            onClick={() => this.props.onCancel()}
          >
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            className="text-right"
            onClick={() => this.props.onConfirm(status)}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(StatusModal)
);
