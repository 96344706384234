import { notify } from './index';

export const all = params => (dispatch, getState, { api }) =>
  api.Surfaces.all(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const one = id => (dispatch, getState, { api }) =>
  api.Surfaces.one(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const save = piece => (dispatch, getState, { api }) =>
  api.Surfaces.save(piece)
    .then(response => {
      dispatch(notify('info', 'Surface saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const remove = id => (dispatch, getState, { api }) =>
  api.Surfaces.del(id)
    .then(response => {
      dispatch(notify('info', 'Surface deleted'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const photo = (id, file) => (dispatch, getState, { api }) =>
  api.Surfaces.photo(id, file)
    .then(response => {
      dispatch(notify('info', 'Surface photo saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
