import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Select from 'react-select';
import get from 'lodash/get';
import { projectType } from '../../helpers/nomenclators';
import defaultImage from '../../assets/img/image_placeholder.jpg';

class SurfaceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surface: {},
      file: null
    };
  }

  componentWillMount() {
    let { surface } = this.props;
    this.setState(ps => ({ ...ps, surface }));
  }

  close() {
    this.setState({
      nameValid: null,
      typeValid: null,
      descriptionValid: null
    });
    this.props.closeModal();
  }

  onChange(name, nameValid, value, valid) {
    let { surface } = this.state;

    this.setState({
      surface: { ...surface, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  validate() {
    let { surface } = this.state;
    let name = this.name;
    let description = this.description;

    this.setState({
      [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
        ? 'has-success'
        : 'has-danger',
      typeValid: surface.type ? 'has-success' : 'has-danger',
      [description.attributes.getNamedItem('namevalid').value]: description
        .validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return name.validity.valid && description.validity.valid;
  }

  handleImageChange(file) {
    let { surface } = this.state;
    let reader = new FileReader();

    reader.onloadend = () => {
      surface.photo = reader.result;
      this.setState({ surface, file: file });
    };
    reader.readAsDataURL(file);
  }

  handleClick() {
    let input = document.createElement('input');

    input.type = 'file';
    input.onchange = e => {
      e.preventDefault();
      this.handleImageChange(e.target.files[0]);
    };
    input.click();
  }

  render() {
    let { surface, nameValid, descriptionValid } = this.state,
      { t } = this.props;

    return (
      <Modal size="lg" isOpen={this.props.show} toggle={() => this.close()}>
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.close()}
        >
          <Trans>
            {surface && surface._id ? 'Edit Surface' : 'New Surface'}
          </Trans>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} className={'display-flex justify-content-center'}>
              <div
                className="avatar-image"
                style={{
                  backgroundImage: `url('${get(
                    surface,
                    'photo',
                    defaultImage
                  )}')`
                }}
              />
            </Col>
            <Col xs={12} className={'justify-content-center text-center'}>
              <Button
                className={'btn-round'}
                color={'info'}
                onClick={() => this.handleClick()}
              >
                <Trans>Change</Trans>
              </Button>
            </Col>
          </Row>
          {surface ? (
            <Form className="form-horizontal">
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + nameValid}
                  >
                    <Label>
                      <Trans>Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.name = node)}
                      namevalid="nameValid"
                      value={surface.name || ''}
                      required="required"
                      onChange={event =>
                        this.onChange(
                          'name',
                          'nameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.typeValid
                    }
                  >
                    <Label>
                      <Trans>Type</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={projectType.map(pt => ({
                        label: t(pt),
                        value: pt
                      }))}
                      value={surface.type}
                      onChange={event =>
                        this.onChange(
                          'type',
                          'typeValid',
                          event ? event.value : null,
                          !!event
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + descriptionValid
                    }
                  >
                    <Label>
                      <Trans>Description</Trans>
                    </Label>
                    <Input
                      type="textarea"
                      innerRef={node => (this.description = node)}
                      namevalid="descriptionValid"
                      value={surface.description || ''}
                      onChange={event =>
                        this.onChange(
                          'description',
                          'descriptionValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <FormGroup className={'has-label'}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={!!surface.inPackage}
                          onChange={event =>
                            this.setState({
                              surface: {
                                ...surface,
                                inPackage: event.target.checked
                              }
                            })
                          }
                        />
                        <span className="form-check-sign" />
                        <Trans>Enable for package</Trans>
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.props.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            onClick={() => {
              if (this.validate()) {
                this.setState({
                  nameValid: null,
                  descriptionValid: null
                });
                this.props.saveSurface(surface, this.state.file);
              }
            }}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(SurfaceModal));
