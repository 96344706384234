import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner
} from '../../../components/index';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { all, logo, photo, remove, save } from '../../../helpers/actions/pros';
import ProCard from './ProCard';
import ProModal from './ProModal';
import ReportModal from './ReportModal';

class Pros extends Component {
  state = {
    loading: true,
    data: [],
    count: 0,
    page: 1,
    limit: 18,
    filters: {},
    search: '',
    pro: null,
    proDelete: null,
    proReport: null
  };

  load() {
    this.setState({ loading: true });

    let { page, limit, search } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(all({ limit, offset, search }))
      .then(({ data, meta }) =>
        this.setState({ data, ...meta, loading: false })
      )
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  filter(search) {
    this.setState({ search, page: 1 }, () => this.load());
  }

  async onSavePro(proEdit, avatar, companyLogo) {
    this.setState({ loading: true, pro: null });

    let { dispatch } = this.props;

    if (proEdit.address && proEdit.address !== '') {
      await geocodeByAddress(proEdit.address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          proEdit.lat = lat;
          proEdit.lng = lng;
        })
        .catch(() => {
          proEdit.lat = null;
          proEdit.lng = null;
        });
    } else {
      proEdit.lat = null;
      proEdit.lng = null;
    }

    //delete proEdit.photoURL;
    delete proEdit.pro.logo;
    dispatch(save(proEdit))
      .then(proRes => {
        let promises = [];

        if (proRes._id && avatar)
          promises.push(dispatch(photo(proRes._id, avatar)));

        if (proRes._id && companyLogo)
          promises.push(dispatch(logo(proRes._id, companyLogo)));

        if (promises.length) {
          Promise.all(promises)
            .then(() => this.load())
            .catch(() => this.load());
        } else this.load();
      })
      .catch(() => this.setState({ loading: false }));
  }

  deletePro(id) {
    this.setState({ loading: true, proDelete: null });

    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let {
      loading,
      data,
      page,
      limit,
      count,
      pro,
      proDelete,
      proReport
    } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event => this.filter(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className={'text-right'} xs={12} md={8}>
                    <Button
                      color="info"
                      onClick={() => this.setState({ pro: {} })}
                    >
                      <Trans>Add</Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          {data.length > 0 ? (
            <div>
              <Row>
                {data.map((contact, key) => (
                  <Col key={key} xs={12} md={6} lg={4}>
                    <ProCard
                      pro={contact}
                      onReport={pro =>
                        this.setState(ps => {
                          return { ...ps, proReport: pro };
                        })
                      }
                      onEdit={proEdit => this.setState({ pro: proEdit })}
                      onDelete={deletePro =>
                        this.setState({ proDelete: deletePro })
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No professionals found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {!!pro ? (
            <ProModal
              show={!!pro}
              pro={pro}
              closeModal={() => this.setState({ pro: null })}
              savePro={(proEdit, avatar, companyLogo) =>
                this.onSavePro(proEdit, avatar, companyLogo)
              }
            />
          ) : null}

          {!proDelete ? null : (
            <AlertConfirm
              message={'The professional cannot be recovered'}
              onCancel={() => this.setState({ proDelete: null })}
              onConfirm={() => this.deletePro(proDelete._id)}
            />
          )}

          {!!proReport ? (
            <ReportModal
              show={!!proReport}
              pro={proReport}
              closeModal={() => this.setState({ proReport: null })}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(state => state)(translate('translations-fr')(Pros));
