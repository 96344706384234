import { notify } from './index';

export const reportProjectsNotes = () => (dispatch, getState, { api }) =>
  api.Reports.reportProjectsNotes().catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const reportProjectsEvents = () => (dispatch, getState, { api }) =>
  api.Reports.reportProjectsEvents().catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const reportDashboard = () => (dispatch, getState, { api }) =>
  api.Reports.reportDashboard().catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const reportFinances = () => (dispatch, getState, { api }) =>
  api.Reports.reportFinances().catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });
