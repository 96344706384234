import { notify } from './index';

export const load = params => (dispatch, getState, { api }) =>
  api.Events.all(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export default {
  load
};
