import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import get from 'lodash/get';
import { proName } from '../../../helpers/formatters';
import defaultAvatar from '../../../assets/img/default-avatar.png';

class ProCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  componentWillReceiveProps() {
    this.setState({ selected: false });
  }

  render() {
    let { selected } = this.state;
    let { pro } = this.props;

    return (
      <Card className={`card-selectable ${selected ? 'selected' : ''}`}>
        <CardHeader
          className={'pt-2'}
          onClick={() => this.setState({ selected: !selected })}
        >
          <Row>
            <Col xs={3} lg={2} className={'pl-1 pr-1'}>
              <div
                className="client-avatar img-raised"
                style={{
                  backgroundImage: `url('${get(
                    pro,
                    'photoURL',
                    defaultAvatar
                  )}')`
                }}
              />
            </Col>
            <Col xs={9} lg={6}>
              <h6>{proName(pro)}</h6>
              {pro.email}
            </Col>
            <Col xs={12} lg={4} className={'text-right pl-1 pr-1'}>
              <Button
                id={`report-${pro._id}`}
                className={'btn-icon'}
                color="info"
                size="sm"
                onClick={() => this.props.onReport(pro)}
              >
                <i className="fa fa-pie-chart icon-action" />
              </Button>
              <UncontrolledTooltip
                placement="left"
                target={`report-${pro._id}`}
                delay={0}
              >
                <Trans>Projects by status</Trans>
              </UncontrolledTooltip>
              <Button
                id={`edit-${pro._id}`}
                className={'btn-icon'}
                color="info"
                size="sm"
                onClick={() => this.props.onEdit(pro)}
              >
                <i className="fa fa-pencil-alt icon-action" />
              </Button>
              <UncontrolledTooltip
                placement="left"
                target={`edit-${pro._id}`}
                delay={0}
              >
                <Trans>Edit</Trans>
              </UncontrolledTooltip>
              <Button
                id={`remove-${pro._id}`}
                className={'btn-icon'}
                color="danger"
                size="sm"
                onClick={() => this.props.onDelete(pro)}
              >
                <i className="fa fa-trash icon-action" />
              </Button>
              <UncontrolledTooltip
                placement="left"
                target={`remove-${pro._id}`}
                delay={0}
              >
                <Trans>Delete</Trans>
              </UncontrolledTooltip>
            </Col>
          </Row>
        </CardHeader>
        <CardBody
          className={'text-center pt-0 pb-0'}
          style={{ display: selected ? 'block' : 'none' }}
        >
          <hr />
          <strong>
            <Trans>Address</Trans>
          </strong>
          <div>
            <span className={'pr-3'}>
              <i className="fa fa-map-marker text-primary icon-action" />
            </span>
            {get(pro, 'address', '-')}
          </div>
          <hr />
          <strong>
            <Trans>Phones</Trans>
          </strong>
          <div>
            <ul className="fa-ul inline-block text-left">
              <li>
                <i className="fa-li fa fa-phone text-primary icon-action" />
                {`${get(pro, 'phone.mobile', '-')}  (Mobile)`}
              </li>
              <li>
                <i className="fa-li fa fa-phone text-primary icon-action" />
                {`${get(pro, 'phone.landline', '-')}  (Landline)`}
              </li>
              <li>
                <i className="fa-li fa fa-phone text-primary icon-action" />
                {`${get(pro, 'phone.work', '-')}  (Work)`}
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default connect(state => state)(translate('translations-fr')(ProCard));
