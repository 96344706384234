import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner
} from '../../components';
import Select from 'react-select';
import { all, photo, remove, save } from '../../helpers/actions/surfaces';
import { projectType } from '../../helpers/nomenclators';
import SurfaceCard from './SurfaceCard';
import SurfaceModal from './SurfaceModal';

class SurfacesList extends Component {
  state = {
    loading: false,
    surfaces: [],
    count: 0,
    page: 1,
    limit: 18,
    search: '',
    type: null,
    surface: null,
    surfaceDelete: null
  };

  componentDidMount() {
    this.load();
  }

  load() {
    this.setState({ loading: true });

    let { page, limit, search, type } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(all({ limit, offset, search, type }))
      .then(({ data, meta }) =>
        this.setState({ surfaces: data, ...meta, loading: false })
      )
      .catch(() => this.setState({ loading: false }));
  }

  onSave(surfaceEdit, photoFile) {
    this.setState({ loading: true, surface: null });

    let { dispatch } = this.props;

    delete surfaceEdit.photo;
    dispatch(save(surfaceEdit))
      .then(surfaceRes => {
        if (surfaceRes._id && photoFile) {
          dispatch(photo(surfaceRes._id, photoFile))
            .then(() => this.load())
            .catch(() => this.load());
        } else this.load();
      })
      .catch(() => this.setState({ loading: false }));
  }

  deleteSurface(id) {
    this.setState({ loading: true, surfaceDelete: null });

    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let {
        loading,
        page,
        limit,
        count,
        surfaces,
        surface,
        surfaceDelete
      } = this.state,
      { t } = this.props;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event =>
                          this.setState(
                            {
                              page: 1,
                              search: event.target.value
                            },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>
                        <Trans>Type</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={projectType.map(pt => ({
                          label: t(pt),
                          value: pt
                        }))}
                        value={this.state.type}
                        onChange={event =>
                          this.setState(
                            {
                              page: 1,
                              type: event ? event.value : null
                            },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col className={'text-right'} xs={12} md={5}>
                    <Button
                      color="info"
                      onClick={() => this.setState({ surface: {} })}
                    >
                      <Trans>Add</Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          {surfaces.length > 0 ? (
            <div>
              <Row>
                {surfaces.map((surface, key) => (
                  <Col key={key} xs={12} sm={12} md={4} lg={4}>
                    <SurfaceCard
                      surface={surface}
                      onEdit={surfaceEdit =>
                        this.setState({ surface: surfaceEdit })
                      }
                      onDelete={deleteSurface =>
                        this.setState({ surfaceDelete: deleteSurface })
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No surfaces found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {!!surface ? (
            <SurfaceModal
              show={!!surface}
              surface={surface}
              closeModal={() => this.setState({ surface: null })}
              saveSurface={(surfaceEdit, photo) =>
                this.onSave(surfaceEdit, photo)
              }
            />
          ) : null}

          {!surfaceDelete ? null : (
            <AlertConfirm
              message={'The surface cannot be recovered'}
              onCancel={() => this.setState({ surfaceDelete: null })}
              onConfirm={() => this.deleteSurface(surfaceDelete._id)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(SurfacesList));
