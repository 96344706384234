import React from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import get from 'lodash/get';
import { dateFormatter } from '../../helpers/formatters';

class EventModal extends React.Component {
  render() {
    let { selectedEvent, hide } = this.props;

    return (
      <Modal size="md" isOpen={selectedEvent !== null} toggle={() => hide()}>
        <ModalHeader className="justify-content-center" toggle={() => hide()}>
          <Trans>Event</Trans>
        </ModalHeader>
        <ModalBody>
          <Table responsive>
            <tbody>
              <tr className={'alert-visit-info-head'}>
                <td className="text-center" colSpan="2">
                  <Trans>Project</Trans>
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <Trans>Name</Trans>
                </td>
                <td className="text-left">
                  {get(selectedEvent.project, 'name', '')}
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <Trans>Address</Trans>
                </td>
                <td className="text-left">
                  {get(selectedEvent.project, 'address', '')}
                </td>
              </tr>
              <tr className={'alert-visit-info-head'}>
                <td className="text-center" colSpan="2">
                  <Trans>Event</Trans>
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <Trans>Title</Trans>
                </td>
                <td className="text-left">{get(selectedEvent, 'title', '')}</td>
              </tr>
              <tr>
                <td className="text-left">
                  <Trans>Start</Trans>
                </td>
                <td className="text-left">
                  {dateFormatter(selectedEvent.start)}
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <Trans>End</Trans>
                </td>
                <td className="text-left">
                  {dateFormatter(selectedEvent.end)}
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="text-left">
                  {get(selectedEvent, 'text', '')}
                </td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(EventModal));
