import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
  Row,
  Col
} from 'reactstrap';
import { AlertConfirm } from '../../../components';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import {
  loadStatuses,
  remove,
  updateStatus
} from '../../../helpers/actions/projects';
import { moneyFormatter } from '../../../helpers/formatters';
import StatusModal from './StatusModal';
import HistoryModal from './HistoryModal';

export default connect()(
  translate()(
    class extends Component {
      state = {
        project: null,
        isOpen: false,
        remove: false,
        history: false,
        status: null,
        statuses: []
      };

      componentWillMount() {
        let { project } = this.props;
        this.setState(ps => ({ ...ps, project }));
      }

      componentWillReceiveProps(nextProps, prevState) {
        this.setState(ps => ({ ...ps, project: nextProps.project }));
      }

      getProjectPrice() {
        let { project } = this.state;
        let price = 0;

        if (project && project.devis && project.devis.length) {
          project.devis.map(
            d =>
              (price += get(d, 'price.ttc', 0) + get(d, 'price.materials', 0))
          );
        } else if (project && project.devisBase) {
          price =
            get(project.devisBase, 'price.ttc', 0) +
            get(project.devisBase, 'price.materials', 0);
        }

        return price;
      }

      remove() {
        let { project } = this.state,
          { dispatch, history } = this.props;
        dispatch(remove(project._id))
          .then(() => history.replace('/projects'))
          .catch(() => this.setState({ remove: false }));
      }

      showStatusForm() {
        let { project } = this.state,
          { dispatch } = this.props;

        dispatch(loadStatuses())
          .then(({ status }) =>
            this.setState({
              status: project.status ? project.status : {},
              statuses: status
            })
          )
          .catch(() => {});
      }

      changeStatus(status) {
        this.setState({ loading: true });

        let { project } = this.state,
          { dispatch } = this.props;

        dispatch(updateStatus(project._id, status))
          .then(res =>
            this.setState(ps => ({
              ...ps,
              status: null,
              project: { ...ps.project, status: res.status }
            }))
          )
          .catch(() => this.setState({ loading: false }));
      }

      render() {
        let { project, remove, history, status, statuses } = this.state,
          { t } = this.props;

        if (!project) return null;

        return (
          <Row>
            <Col>
              <Navbar color={'white'} expand="lg">
                <Container fluid className={'project-nav p-0'}>
                  <div className="navbar-wrapper">
                    <div>
                      <h6>
                        <Link
                          to={`/projects/${project._id}`}
                          className="nav-link p-0"
                        >
                          {get(project, 'name', '')}
                        </Link>
                      </h6>
                      <h6>
                        <small>
                          <Trans>Price</Trans> :
                        </small>{' '}
                        {moneyFormatter(this.getProjectPrice())}
                        <span className="ml-4">
                          <small>
                            <Trans>Status</Trans> :
                          </small>{' '}
                          <span
                            className={'status-button'}
                            onClick={() => this.showStatusForm()}
                          >
                            {t(project.status)}
                          </span>
                        </span>
                      </h6>
                    </div>
                  </div>
                  <NavbarToggler
                    onClick={() =>
                      this.setState({ isOpen: !this.state.isOpen })
                    }
                  >
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                  </NavbarToggler>
                  <Collapse
                    isOpen={this.state.isOpen}
                    navbar
                    className="justify-content-end"
                  >
                    <Nav navbar>
                      <NavItem>
                        <Link to={'/projects'} className="nav-link">
                          <i className="fa fa-list" /> <Trans>Projects</Trans>
                        </Link>
                      </NavItem>
                      {project._id &&
                      project.type &&
                      project.type === 'compound-son' &&
                      project.parent ? (
                        <NavItem>
                          <Link
                            to={`/projects/${project.parent}`}
                            className="nav-link"
                          >
                            <i className="fa fa-home" /> <Trans>Parent</Trans>
                          </Link>
                        </NavItem>
                      ) : null}
                      {project._id
                        ? [
                            <NavItem key={'details'}>
                              <Link
                                to={`/projects/${project._id}`}
                                className="nav-link"
                              >
                                <i className="fa fa-eye" />{' '}
                                <Trans>Details</Trans>
                              </Link>
                            </NavItem>,
                            <NavItem key={'history'}>
                              <NavLink
                                onClick={() => this.setState({ history: true })}
                                className="nav-link"
                              >
                                <i className="fa fa-history" />{' '}
                                <Trans>History</Trans>
                              </NavLink>
                            </NavItem>,
                            <NavItem key={'status'}>
                              <NavLink
                                onClick={() => this.showStatusForm()}
                                className="nav-link"
                              >
                                <i className="fa fa-cog" />{' '}
                                <Trans>Change Status</Trans>
                              </NavLink>
                            </NavItem>,
                            <NavItem key={'edit'}>
                              <Link
                                to={`/projects/${project._id}/edit`}
                                className="nav-link"
                              >
                                <i className="fa fa-pencil-alt" />{' '}
                                <Trans>Edit</Trans>
                              </Link>
                            </NavItem>,
                            <NavItem key={'delete'}>
                              <NavLink
                                onClick={() => this.setState({ remove: true })}
                                className="nav-link"
                              >
                                <i className="fa fa-trash" />{' '}
                                <Trans>Delete</Trans>
                              </NavLink>
                            </NavItem>
                          ]
                        : null}
                    </Nav>
                  </Collapse>
                </Container>
              </Navbar>

              {status ? (
                <StatusModal
                  show={!!status}
                  status={status}
                  statuses={statuses}
                  onCancel={() => this.setState({ status: null })}
                  onConfirm={newStatus => this.changeStatus(newStatus)}
                />
              ) : null}

              {history ? (
                <HistoryModal
                  show={history}
                  project={project}
                  onCancel={() => this.setState({ history: false })}
                />
              ) : null}

              {!remove ? null : (
                <AlertConfirm
                  message={'Do you want to remove this project?'}
                  onCancel={() => this.setState({ remove: false })}
                  onConfirm={() => this.remove()}
                />
              )}
            </Col>
          </Row>
        );
      }
    }
  )
);
