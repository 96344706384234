import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { SimpleCheckbox } from '../../../components';

class DevisStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null
    };
  }

  componentWillMount() {
    let { status } = this.props;
    this.setState(ps => ({ ...ps, status }));
  }

  cleanModal() {
    this.setState({
      status: null,
      statusValid: null
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  onChange(name, nameValid, value, valid) {
    this.setState({
      [name]: value,
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  validate() {
    let { status } = this.state;

    this.setState({
      statusValid: status ? 'has-success' : 'has-danger'
    });

    return !!status;
  }

  onSaveStatus() {
    let { status } = this.state;
    if (this.validate()) {
      this.cleanModal();
      this.props.onConfirm(status);
    }
  }

  render() {
    let { status } = this.state,
      { t } = this.props;

    let selectOptions = [
      { value: 'CREATED', label: t('CREATED') },
      { value: 'SENT', label: t('SENT') },
      { value: 'APPROVED', label: t('APPROVED') },
      { value: 'REFUSED', label: t('REFUSED') },
      { value: 'ABANDONED', label: t('ABANDONED') }
    ];

    return (
      <Modal
        size="md"
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          <Trans>Change Status</Trans>
        </ModalHeader>
        <ModalBody>
          {selectOptions
            ? selectOptions.map((st, key) => (
                <Row key={key}>
                  <Col>
                    <SimpleCheckbox
                      label={st.label}
                      inputProps={{
                        value: st.value,
                        checked: st.value === status,
                        onChange: () =>
                          this.onChange(
                            'status',
                            'statusValid',
                            st.value ? st.value : null,
                            !!st.value
                          )
                      }}
                    />
                  </Col>
                </Row>
              ))
            : null}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button color="info" onClick={() => this.onSaveStatus()}>
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(DevisStatusModal));
