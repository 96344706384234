import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Spinner, GoogleMapLibrary } from '../../../components';
import Select from 'react-select';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { photo, save } from '../../../helpers/actions/clients';
import { clientOrigin } from '../../../helpers/nomenclators';
import Gallery from '../../Images/Gallery';
import defaultAvatar from '../../../assets/img/default-avatar.png';

class ClientModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      client: {
        firstname: '',
        lastname: '',
        email: '',
        address: '',
        phone: {},
        client: {
          origin: 'provider'
        }
      },
      file: null,
      showGallery: false
    };
  }

  componentWillMount() {
    let { client } = this.props;
    this.setState(ps => ({ ...ps, loading: true }));

    if (client && !client.phone) client.phone = {};

    if (client && !client.client) client.client = {};

    this.setState({
      client: client._id
        ? client
        : {
            firstname: '',
            lastname: '',
            email: '',
            address: '',
            phone: {},
            photoURL: client.photoUrl ? client.photoUrl : null,
            client: {
              origin: 'provider'
            }
          },
      file: null,
      firstnameValid: '',
      lastnameValid: '',
      emailValid: '',
      addressValid: '',
      mobileValid: '',
      workValid: '',
      landlineValid: '',
      loading: false
    });
  }

  handleImageChange(file) {
    let { client } = this.state;
    let reader = new FileReader();

    reader.onloadend = () => {
      client.photoURL = reader.result;
      this.setState({ client, file: file });
    };
    reader.readAsDataURL(file);
  }

  handleClick() {
    let input = document.createElement('input');

    input.type = 'file';
    input.onchange = e => {
      e.preventDefault();
      this.handleImageChange(e.target.files[0]);
    };
    input.click();
  }

  onChangeClient(name, nameValid, value, valid) {
    let { client } = this.state;

    if (name === 'mobile' || name === 'landline' || name === 'work') {
      this.setState({
        client: { ...client, phone: { ...client.phone, [name]: value } },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      });
    } else {
      if (name === 'firstname' || name === 'lastname' || name === 'origin') {
        this.setState({
          client: { ...client, client: { ...client.client, [name]: value } },
          [nameValid]: valid ? 'has-success' : 'has-danger'
        });
      } else {
        this.setState({
          client: { ...client, [name]: value },
          [nameValid]: valid ? 'has-success' : 'has-danger'
        });
      }
    }
  }

  validateClient() {
    let { client } = this.state;
    let firstname = this.firstname;
    let lastname = this.lastname;
    let email = this.email;
    let address = this.address;
    let mobile = this.mobile;
    let work = this.work;
    let landline = this.landline;

    this.setState({
      [firstname.attributes.getNamedItem('namevalid').value]: firstname.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [lastname.attributes.getNamedItem('namevalid').value]: lastname.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [email.attributes.getNamedItem('namevalid').value]: email.validity.valid
        ? 'has-success'
        : 'has-danger',
      originValid: client.origin ? 'has-success' : 'has-danger',
      [address.attributes.getNamedItem('namevalid').value]: address.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [mobile.attributes.getNamedItem('namevalid').value]: mobile.validity.valid
        ? 'has-success'
        : 'has-danger',
      [work.attributes.getNamedItem('namevalid').value]: work.validity.valid
        ? 'has-success'
        : 'has-danger',
      [landline.attributes.getNamedItem('namevalid').value]: landline.validity
        .valid
        ? 'has-success'
        : 'has-danger'
    });

    return (
      firstname.validity.valid &&
      lastname.validity.valid &&
      email.validity.valid &&
      address.validity.valid &&
      mobile.validity.valid &&
      work.validity.valid &&
      landline.validity.valid
    );
  }

  async onSaveClient() {
    if (this.validateClient()) {
      this.setState({ loading: true });

      let { client, file } = this.state,
        { dispatch } = this.props;

      if (client.address && client.address !== '') {
        await geocodeByAddress(client.address)
          .then(results => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            client.lat = lat;
            client.lng = lng;
          })
          .catch(() => {
            client.lat = null;
            client.lng = null;
          });
      } else {
        client.lat = null;
        client.lng = null;
      }

      //delete client.photoURL;
      dispatch(save(client))
        .then(clientRes => {
          if (clientRes._id && file) {
            dispatch(photo(clientRes._id, file))
              .then(() => this.props.onConfirm(clientRes))
              .catch(() => this.props.onConfirm(clientRes));
          } else this.props.onConfirm(clientRes);
        })
        .catch(() => this.props.onCancel());
    }
  }

  render() {
    let { loading, client, showGallery } = this.state,
      { t } = this.props;

    return (
      <Modal
        size="lg"
        isOpen={this.props.show}
        toggle={() => this.props.onCancel()}
      >
        {loading ? <Spinner /> : null}
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.props.onCancel()}
        >
          {client._id ? t('Edit Client') : t('New Client')}
        </ModalHeader>
        <ModalBody>
          {client ? (
            <Form className="form-horizontal">
              <Row>
                <Col xs={12} className={'display-flex justify-content-center'}>
                  <div
                    className="avatar-image"
                    style={{
                      backgroundImage: `url('${
                        client.photoURL ? client.photoURL : defaultAvatar
                      }')`
                    }}
                  />
                </Col>
                <Col xs={12} className={'justify-content-center text-center'}>
                  <Button
                    color={'info'}
                    round="true"
                    onClick={() => this.handleClick()}
                  >
                    <Trans>Change</Trans>
                  </Button>
                  <Button
                    color="info"
                    onClick={() => this.setState({ showGallery: true })}
                  >
                    <Trans>Gallery</Trans>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.firstnameValid
                    }
                  >
                    <Label>
                      <Trans>First Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.firstname = node)}
                      namevalid="firstnameValid"
                      value={client.client.firstname || ''}
                      required="required"
                      onChange={event =>
                        this.onChangeClient(
                          'firstname',
                          'firstnameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.lastnameValid
                    }
                  >
                    <Label>
                      <Trans>Last Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.lastname = node)}
                      namevalid="lastnameValid"
                      value={client.client.lastname || ''}
                      onChange={event =>
                        this.onChangeClient(
                          'lastname',
                          'lastnameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.emailValid
                    }
                  >
                    <Label>
                      <Trans>Email</Trans>
                    </Label>
                    <Input
                      type="email"
                      innerRef={node => (this.email = node)}
                      namevalid="emailValid"
                      value={client.email || ''}
                      required="required"
                      onChange={event =>
                        this.onChangeClient(
                          'email',
                          'emailValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.originValid
                    }
                  >
                    <Label>
                      <Trans>Origin</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={clientOrigin.map(c => ({
                        label: t(c),
                        value: c
                      }))}
                      value={client.client.origin}
                      onChange={event =>
                        this.onChangeClient(
                          'origin',
                          'originValid',
                          event ? event.value : null,
                          !!event
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.addressValid
                    }
                  >
                    <Label>
                      <Trans>Address</Trans>
                    </Label>
                    <GoogleMapLibrary>
                      <PlacesAutocomplete
                        value={client.address ? client.address : ''}
                        onChange={address =>
                          this.onChangeClient(
                            'address',
                            'addressValid',
                            address,
                            true
                          )
                        }
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps
                        }) => (
                          <div>
                            <input
                              ref={node => (this.address = node)}
                              {...getInputProps({
                                className: 'form-control',
                                namevalid: 'addressValid'
                              })}
                            />
                            <ul
                              className={
                                'dropdown-menu inner ' +
                                (suggestions.length === 0 ? '' : 'show')
                              }
                            >
                              {suggestions.map(suggestion => (
                                <li {...getSuggestionItemProps(suggestion)}>
                                  <a href="/#" className="dropdown-item">
                                    <span className="text">
                                      {suggestion.description}
                                    </span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </GoogleMapLibrary>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.mobileValid
                    }
                  >
                    <Label>
                      <Trans>Mobile</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.mobile = node)}
                      namevalid="mobileValid"
                      value={client.phone.mobile || ''}
                      onChange={event =>
                        this.onChangeClient(
                          'mobile',
                          'mobileValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.workValid
                    }
                  >
                    <Label>
                      <Trans>Work</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.work = node)}
                      namevalid="workValid"
                      value={client.phone.work || ''}
                      onChange={event =>
                        this.onChangeClient(
                          'work',
                          'workValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.landlineValid
                    }
                  >
                    <Label>
                      <Trans>Landline</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.landline = node)}
                      namevalid="landlineValid"
                      value={client.phone.landline || ''}
                      onChange={event =>
                        this.onChangeClient(
                          'landline',
                          'landlineValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.props.onCancel()}>
            <Trans>Close</Trans>
          </Button>
          <Button color="info" onClick={() => this.onSaveClient()}>
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>

        {showGallery ? (
          <Gallery
            type={'profile'}
            selectImage={url =>
              this.setState(prevState => {
                return {
                  ...prevState,
                  showGallery: false,
                  client: {
                    ...prevState.client,
                    photoURL: url
                  }
                };
              })
            }
            closeGallery={() =>
              this.setState(prevState => {
                return { ...prevState, showGallery: false };
              })
            }
          />
        ) : null}
      </Modal>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(ClientModal)
);
