import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Timeline } from '../../../components';
import 'moment/locale/fr';
import { loadHistory } from '../../../helpers/actions/projects';
import { dateFormatter } from '../../../helpers/formatters';

class HistoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      history: []
    };
  }

  componentWillMount() {
    let { dispatch, project } = this.props;

    if (project._id) {
      dispatch(loadHistory(project._id)).then(({ data }) => {
        this.setState({ history: data });
      });
    }
  }

  loadHistory() {
    let { project, dispatch } = this.props;

    if (project._id) {
      dispatch(loadHistory(project._id)).then(({ data }) => {
        this.setState({ history: data });
      });
    }
  }

  buildHistory() {
    let { history } = this.state;

    if (history) {
      let stories = history.map((h, index) => {
        let body = null;

        switch (h.type) {
          case 'media':
            body = [
              h.title,
              <img src={h.body} key={'img' + index} alt="..." />
            ];
            break;

          case 'visit': {
            let { comments_from_pro, comments_from_client } = JSON.parse(
              h.body
            );

            body = (
              <dl>
                <dt>
                  <Trans>Comments from pro</Trans>
                </dt>
                <dd>
                  {comments_from_pro || (
                    <small>
                      <Trans>Empty</Trans>
                    </small>
                  )}
                </dd>
                <dt>
                  <Trans>Comments from client</Trans>
                </dt>
                <dd>
                  {comments_from_client || (
                    <small>
                      <Trans>Empty</Trans>
                    </small>
                  )}
                </dd>
              </dl>
            );

            break;
          }

          default:
            body = (
              <div>
                <p>
                  <strong>{h.title}</strong>
                </p>
                <p>{h.body}</p>
              </div>
            );
            break;
        }

        return {
          inverted: index % 2 === 0,
          badgeColor: 'info',
          badgeIcon: 'now-ui-icons business_briefcase-24',
          titleColor: 'info',
          body,
          footerTitle: dateFormatter(h.when, 'llll')
        };
      });

      return <Timeline stories={stories} />;
    }
  }

  render() {
    return (
      <Modal
        size={'lg'}
        isOpen={this.props.show}
        toggle={() => this.props.onCancel()}
        className={'bg-light'}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.props.onCancel()}
        >
          <Trans>History</Trans>
        </ModalHeader>
        <ModalBody className="card-timeline">
          <Row>
            <Col xs={12}>
              <Button
                className="float-right"
                size="sm"
                color={'default'}
                onClick={() => this.loadHistory()}
              >
                <i className="now-ui-icons arrows-1_refresh-69" />
              </Button>
            </Col>
          </Row>
          <Col xs={12}>{this.buildHistory()}</Col>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            className="text-center"
            onClick={() => this.props.onCancel()}
          >
            <Trans>Close</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(HistoryModal)
);
