import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Select from 'react-select';
import PlacesAutocomplete from 'react-places-autocomplete';
import get from 'lodash/get';
import {
  businessTypes,
  skills,
  specialities
} from '../../../helpers/nomenclators';
import Gallery from '../../Images/Gallery';
import defaultAvatar from '../../../assets/img/default-avatar.png';
import { GoogleMapLibrary } from '../../../components';

class ProModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pro: {
        email: '',
        displayName: '',
        address: '',
        phone: {},
        pro: {}
      },
      filePhoto: null,
      fileLogo: null,
      showGallery: false
    };
  }

  componentWillMount() {
    let { pro } = this.props;

    if (pro && !pro.phone) pro.phone = {};

    if (pro && !pro.pro) pro.pro = {};

    this.setState(ps => ({
      ...ps,
      pro: pro
        ? pro
        : {
            email: '',
            displayName: '',
            address: '',
            phone: {},
            pro: {}
          },
      filePhoto: null,
      fileLogo: null,
      emailValid: '',
      displayNameValid: '',
      addressValid: '',
      mobileValid: '',
      workValid: '',
      landlineValid: '',
      commercialNameValid: '',
      commercialStatusValid: '',
      siretValid: '',
      sirenValid: '',
      managerValid: '',
      skillsValid: [],
      specialities: [],
      descriptionValid: '',
      delivery_address: ''
    }));
  }

  close() {
    this.props.closeModal();
  }

  handlePhotoChange(file) {
    let { pro } = this.state;
    let reader = new FileReader();

    reader.onloadend = () => {
      pro.photoURL = reader.result;
      this.setState(ps => ({ ...ps, pro, filePhoto: file }));
    };
    reader.readAsDataURL(file);
  }

  handleClickPhoto() {
    let input = document.createElement('input');

    input.type = 'file';
    input.onchange = e => {
      e.preventDefault();
      this.handlePhotoChange(e.target.files[0]);
    };
    input.click();
  }

  handleLogoChange(file) {
    let { pro } = this.state;
    let reader = new FileReader();

    reader.onloadend = () => {
      pro.pro.logo = reader.result;
      this.setState(ps => ({ ...ps, pro, fileLogo: file }));
    };
    reader.readAsDataURL(file);
  }

  handleClickLogo() {
    let input = document.createElement('input');

    input.type = 'file';
    input.onchange = e => {
      e.preventDefault();
      this.handleLogoChange(e.target.files[0]);
    };
    input.click();
  }

  onChangePro(name, nameValid, value, valid) {
    let { pro } = this.state;
    if (name === 'mobile' || name === 'landline' || name === 'work') {
      this.setState(ps => ({
        ...ps,
        pro: { ...pro, phone: { ...pro.phone, [name]: value } },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      }));
    } else {
      if (
        name === 'commercial_name' ||
        name === 'commercial_status' ||
        name === 'manager' ||
        name === 'siret' ||
        name === 'siren' ||
        name === 'skills' ||
        name === 'specialities' ||
        name === 'description' ||
        name === 'delivery_address'
      ) {
        this.setState(ps => ({
          ...ps,
          pro: {
            ...ps.pro,
            pro: { ...ps.pro.pro, [name]: value }
          },
          [nameValid]: valid ? 'has-success' : 'has-danger'
        }));
      } else {
        this.setState(ps => ({
          ...ps,
          pro: { ...pro, [name]: value },
          [nameValid]: valid ? 'has-success' : 'has-danger'
        }));
      }
    }
  }

  validatePro() {
    let { pro } = this.state;
    let email = this.email;
    let displayName = this.displayName;
    let address = this.address;
    let mobile = this.mobile;
    let work = this.work;
    let landline = this.landline;
    let commercialName = this.commercialName;
    let siret = this.siret;
    let siren = this.siren;
    let manager = this.manager;
    let description = this.description;
    let delivery_address = this.delivery_address;

    this.setState({
      [email.attributes.getNamedItem('namevalid').value]: email.validity.valid
        ? 'has-success'
        : 'has-danger',
      [displayName.attributes.getNamedItem('namevalid').value]: displayName
        .validity.valid
        ? 'has-success'
        : 'has-danger',
      [address.attributes.getNamedItem('namevalid').value]: address.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [mobile.attributes.getNamedItem('namevalid').value]: mobile.validity.valid
        ? 'has-success'
        : 'has-danger',
      [work.attributes.getNamedItem('namevalid').value]: work.validity.valid
        ? 'has-success'
        : 'has-danger',
      [landline.attributes.getNamedItem('namevalid').value]: landline.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [commercialName.attributes.getNamedItem('namevalid')
        .value]: commercialName.validity.valid ? 'has-success' : 'has-danger',
      commercialStatusValid: 'has-success',
      [siret.attributes.getNamedItem('namevalid').value]: siret.validity.valid
        ? 'has-success'
        : 'has-danger',
      [siren.attributes.getNamedItem('namevalid').value]: siren.validity.valid
        ? 'has-success'
        : 'has-danger',
      [manager.attributes.getNamedItem('namevalid').value]: manager.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      skillsValid: pro.pro.skills ? 'has-success' : 'has-success',
      specialitiesValid: pro.pro.specialities ? 'has-success' : 'has-success',
      [description.attributes.getNamedItem('namevalid').value]: description
        .validity.valid
        ? 'has-success'
        : 'has-danger',
      [delivery_address.attributes.getNamedItem('namevalid')
        .value]: delivery_address.validity.valid ? 'has-success' : 'has-danger'
    });

    return (
      email.validity.valid &&
      displayName.validity.valid &&
      address.validity.valid &&
      mobile.validity.valid &&
      work.validity.valid &&
      landline.validity.valid &&
      commercialName.validity.valid &&
      siret.validity.valid &&
      siren.validity.valid &&
      manager.validity.valid &&
      description.validity.valid &&
      delivery_address.validity.valid &&
      pro.pro.skills &&
      pro.pro.specialities
    );
  }

  savePro() {
    if (this.validatePro()) {
      let { pro, filePhoto, fileLogo } = this.state;
      this.props.savePro({ ...pro }, { ...filePhoto }, { ...fileLogo });
    }
  }

  render() {
    let {
      pro,
      emailValid,
      displayNameValid,
      addressValid,
      mobileValid,
      workValid,
      landlineValid,
      commercialNameValid,
      commercialStatusValid,
      siretValid,
      sirenValid,
      managerValid,
      skillsValid,
      specialitiesValid,
      descriptionValid,
      delivery_addressValid,
      showGallery
    } = this.state;

    let { t } = this.props;

    return (
      <Modal size="lg" isOpen={this.props.show} toggle={() => this.close()}>
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.close()}
        >
          {pro._id ? t('Edit Professional') : t('New Professional')}
        </ModalHeader>
        <ModalBody>
          {pro ? (
            <Form>
              <Row>
                <Col xs={12}>
                  <h6>
                    <Trans>Personal Information</Trans>
                  </h6>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <Col
                      xs={12}
                      className={'display-flex justify-content-center'}
                    >
                      <div
                        className="avatar-image"
                        style={{
                          backgroundImage: `url('${get(
                            pro,
                            'photoURL',
                            defaultAvatar
                          )}')`
                        }}
                      />
                    </Col>
                    <Col
                      xs={12}
                      className={'justify-content-center text-center'}
                    >
                      <Button
                        color={'info'}
                        round="true"
                        onClick={() => this.handleClickPhoto()}
                      >
                        <Trans>Change</Trans>
                      </Button>
                      <Button
                        color="info"
                        onClick={() => this.setState({ showGallery: true })}
                      >
                        <Trans>Gallery</Trans>
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        className={'has-label form-validation-40 ' + emailValid}
                      >
                        <Label>
                          <Trans>Email</Trans>
                        </Label>
                        <Input
                          type="email"
                          innerRef={node => (this.email = node)}
                          namevalid="emailValid"
                          value={pro.email || ''}
                          required="required"
                          onChange={event =>
                            this.onChangePro(
                              'email',
                              'emailValid',
                              event.target.value,
                              event.target.validity.valid
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' + displayNameValid
                        }
                      >
                        <Label>
                          <trans>Display Name</trans>
                        </Label>
                        <Input
                          type="text"
                          innerRef={node => (this.displayName = node)}
                          namevalid="displayNameValid"
                          value={pro.displayName || ''}
                          required="required"
                          onChange={event =>
                            this.onChangePro(
                              'displayName',
                              'displayNameValid',
                              event.target.value,
                              event.target.validity.valid
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + addressValid}
                  >
                    <Label>
                      <Trans>Address</Trans>
                    </Label>
                    <GoogleMapLibrary>
                      <PlacesAutocomplete
                        value={pro.address ? pro.address : ''}
                        onChange={address =>
                          this.onChangePro(
                            'address',
                            'addressValid',
                            address,
                            true
                          )
                        }
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps
                        }) => (
                          <div>
                            <input
                              ref={node => (this.address = node)}
                              {...getInputProps({
                                className: 'form-control',
                                namevalid: 'addressValid'
                              })}
                            />
                            <ul
                              className={
                                'dropdown-menu inner ' +
                                (suggestions.length === 0 ? '' : 'show')
                              }
                            >
                              {suggestions.map(suggestion => (
                                <li {...getSuggestionItemProps(suggestion)}>
                                  <a href="/#" className="dropdown-item">
                                    <span className="text">
                                      {suggestion.description}
                                    </span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </GoogleMapLibrary>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + mobileValid}
                  >
                    <Label>
                      <Trans>Mobile</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.mobile = node)}
                      namevalid="mobileValid"
                      value={pro.phone.mobile || ''}
                      onChange={event =>
                        this.onChangePro(
                          'mobile',
                          'mobileValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + workValid}
                  >
                    <Label>
                      <trans>Work</trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.work = node)}
                      namevalid="workValid"
                      value={pro.phone.work || ''}
                      onChange={event =>
                        this.onChangePro(
                          'work',
                          'workValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + landlineValid}
                  >
                    <Label>
                      <Trans>Landline</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.landline = node)}
                      namevalid="landlineValid"
                      value={pro.phone.landline || ''}
                      onChange={event =>
                        this.onChangePro(
                          'landline',
                          'landlineValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + specialitiesValid
                    }
                  >
                    <Label>
                      <Trans>Specialities</Trans>
                    </Label>
                    <Select
                      className="primary"
                      multi={true}
                      closeOnSelect={false}
                      options={specialities.map(p => ({
                        label: t(p),
                        value: p
                      }))}
                      value={pro.pro.specialities}
                      onChange={event =>
                        this.onChangePro(
                          'specialities',
                          'specialitiesValid',
                          event.map(e => e.value),
                          !!event.length
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + skillsValid}
                  >
                    <Label>
                      <Trans>Skills</Trans>
                    </Label>
                    <Select
                      className="primary"
                      multi={true}
                      closeOnSelect={false}
                      options={skills.map(p => ({
                        label: t(p),
                        value: p
                      }))}
                      value={pro.pro.skills}
                      onChange={event =>
                        this.onChangePro(
                          'skills',
                          'skillsValid',
                          event.map(e => e.value),
                          !!event.length
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + delivery_addressValid
                    }
                  >
                    <Label>
                      <Trans>Delivery Address</Trans>
                    </Label>
                    <GoogleMapLibrary>
                      <PlacesAutocomplete
                        value={
                          pro.pro.delivery_address
                            ? pro.pro.delivery_address
                            : ''
                        }
                        onChange={delivery_address =>
                          this.onChangePro(
                            'delivery_address',
                            'delivery_addressValid',
                            delivery_address,
                            true
                          )
                        }
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps
                        }) => (
                          <div>
                            <input
                              ref={node => (this.delivery_address = node)}
                              {...getInputProps({
                                className: 'form-control',
                                namevalid: 'delivery_addressValid'
                              })}
                            />
                            <ul
                              className={
                                'dropdown-menu inner ' +
                                (suggestions.length === 0 ? '' : 'show')
                              }
                            >
                              {suggestions.map(suggestion => (
                                <li {...getSuggestionItemProps(suggestion)}>
                                  <a href="/#" className="dropdown-item">
                                    <span className="text">
                                      {suggestion.description}
                                    </span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </GoogleMapLibrary>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + descriptionValid
                    }
                  >
                    <Label>
                      <Trans>Description</Trans>
                    </Label>
                    <Input
                      type="textarea"
                      innerRef={node => (this.description = node)}
                      namevalid="descriptionValid"
                      value={pro.pro.description || ''}
                      onChange={event =>
                        this.onChangePro(
                          'description',
                          'descriptionValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={12}>
                  <h6>
                    <Trans>Company Information</Trans>
                  </h6>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <Col
                      xs={12}
                      className={'display-flex justify-content-center'}
                    >
                      <div
                        className="avatar-image"
                        style={{
                          backgroundImage: `url('${get(
                            pro,
                            'pro.logo',
                            defaultAvatar
                          )}')`
                        }}
                      />
                    </Col>
                    <Col
                      xs={12}
                      className={'justify-content-center text-center'}
                    >
                      <Button
                        color={'info'}
                        round="true"
                        onClick={() => this.handleClickLogo()}
                      >
                        <Trans>Change</Trans>
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' + commercialNameValid
                        }
                      >
                        <Label>
                          <Trans>Commercial Name</Trans>
                        </Label>
                        <Input
                          type="text"
                          innerRef={node => (this.commercialName = node)}
                          namevalid="commercialNameValid"
                          value={get(pro.pro, 'commercial_name', '')}
                          onChange={event =>
                            this.onChangePro(
                              'commercial_name',
                              'commercialNameValid',
                              event.target.value,
                              event.target.validity.valid
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' +
                          commercialStatusValid
                        }
                      >
                        <Label>
                          <Trans>Commercial Status</Trans>
                        </Label>
                        <Select
                          className="primary"
                          value={get(pro.pro, 'commercial_status', null)}
                          options={businessTypes.map(p => ({
                            label: t(p),
                            value: p
                          }))}
                          onChange={event => {
                            this.onChangePro(
                              'commercial_status',
                              'commercialStatusValid',
                              event ? event.value : null,
                              true
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + siretValid}
                  >
                    <Label>
                      <Trans>Siret</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.siret = node)}
                      namevalid="siretValid"
                      value={pro.pro.siret || ''}
                      onChange={event =>
                        this.onChangePro(
                          'siret',
                          'siretValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + sirenValid}
                  >
                    <Label>
                      <Trans>Siren</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.siren = node)}
                      namevalid="sirenValid"
                      value={pro.pro.siren || ''}
                      onChange={event =>
                        this.onChangePro(
                          'siren',
                          'sirenValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={'has-label form-validation-40 ' + managerValid}
                  >
                    <Label>
                      <Trans>Manager</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.manager = node)}
                      namevalid="managerValid"
                      value={pro.pro.manager || ''}
                      onChange={event =>
                        this.onChangePro(
                          'manager',
                          'managerValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.props.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            onClick={() => {
              if (this.validatePro()) {
                this.props.savePro(
                  pro,
                  this.state.filePhoto,
                  this.state.fileLogo
                );
              }
            }}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>

        {showGallery ? (
          <Gallery
            type={'profile'}
            selectImage={url =>
              this.setState(prevState => {
                return {
                  ...prevState,
                  showGallery: false,
                  pro: {
                    ...prevState.pro,
                    photoURL: url
                  }
                };
              })
            }
            closeGallery={() =>
              this.setState(prevState => {
                return { ...prevState, showGallery: false };
              })
            }
          />
        ) : null}
      </Modal>
    );
  }
}

export default connect(state => state)(translate('translations-fr')(ProModal));
