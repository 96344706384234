import React from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import moment from 'moment';
import get from 'lodash/get';
import { clientName, proName } from '../../helpers/formatters';

class VisitModal extends React.Component {
  render() {
    let { selectedVisit, hide } = this.props;

    return (
      <Modal size="md" isOpen={selectedVisit !== null} toggle={() => hide()}>
        <ModalHeader className="justify-content-center" toggle={() => hide()}>
          <Trans>Visit</Trans>{' '}
          <label> - {moment(selectedVisit.start).format('lll')}</label>
        </ModalHeader>
        <ModalBody>
          <Table responsive>
            <tbody>
              <tr className={'alert-visit-info-head'}>
                <td className="text-center" colSpan="2">
                  <Trans>Project</Trans>
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <Trans>Name</Trans>
                </td>
                <td className="text-left">
                  {get(selectedVisit.project, 'name', '')}
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <Trans>Address</Trans>
                </td>
                <td className="text-left">
                  {get(selectedVisit.project, 'address', '')}
                </td>
              </tr>
              <tr className={'alert-visit-info-head'}>
                <td className="text-center" colSpan="2">
                  <Trans>Client</Trans>
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <Trans>Name</Trans>
                </td>
                <td className="text-left">
                  {clientName(selectedVisit.client)}
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <Trans>Phone</Trans>
                </td>
                <td className="text-left">
                  <a
                    href={`tel:${get(
                      selectedVisit.client,
                      'phone.mobile',
                      ''
                    )}`}
                  >
                    {get(selectedVisit.client, 'phone.mobile', '')}
                  </a>
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <Trans>Email</Trans>
                </td>
                <td className="text-left">
                  {get(selectedVisit.client, 'email', '')}
                </td>
              </tr>
              <tr className={'alert-visit-info-head'}>
                <td className="text-center" colSpan="2">
                  <Trans>Professional</Trans>
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <Trans>Name</Trans>
                </td>
                <td className="text-left">{proName(selectedVisit.pro)}</td>
              </tr>
              <tr>
                <td className="text-left">
                  <Trans>Email</Trans>
                </td>
                <td className="text-left">
                  {get(selectedVisit.pro, 'email', '')}
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <Trans>Phone</Trans>
                </td>
                <td className="text-left">
                  <a href={`tel:${get(selectedVisit.pro, 'phone.mobile', '')}`}>
                    {get(selectedVisit.pro, 'phone.mobile', '')}
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(VisitModal));
