import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';
import { reportProjectsStatusUser } from '../../../helpers/actions/projects';
import { statusColors } from '../../../helpers/nomenclators';

const pieOptions = {
  legend: {
    display: false
  }
};

class ReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pro: null,
      statusProject: {
        status: [],
        counts: []
      },
      loading: true
    };
  }

  load() {
    let { pro, dispatch } = this.props;
    let { statusProject } = this.state;

    if (pro && pro._id) {
      dispatch(reportProjectsStatusUser({ id: pro._id, role: 'pro' }))
        .then(({ data }) => {
          statusProject.status = [];
          statusProject.counts = [];

          data.forEach(status => {
            statusProject.status.push(status._id);
            statusProject.counts.push(status.count);
          });
          this.setState({ pro, statusProject, loading: false });
        })
        .catch(() => {
          this.props.closeModal();
        });
    }
  }

  componentWillMount() {
    this.load();
  }

  close() {
    this.setState(
      {
        pro: null,
        statusProject: {
          status: [],
          counts: []
        }
      },
      () => {
        this.props.closeModal();
      }
    );
  }

  render() {
    let { pro, statusProject } = this.state,
      { t } = this.props;

    return (
      <Modal
        size={this.props.size}
        isOpen={this.props.show}
        toggle={() => this.close()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.close()}
        >
          {t('Report')}
        </ModalHeader>
        <ModalBody>
          {pro && statusProject.status.length ? (
            <div>
              <Row>
                <Doughnut
                  data={{
                    labels: statusProject.status,
                    datasets: [
                      {
                        backgroundColor: statusColors,
                        data: statusProject.counts
                      }
                    ]
                  }}
                  options={pieOptions}
                />
              </Row>
              <Table responsive>
                <tbody>
                  {statusProject.status.map((status, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <span className={'mr-3'}>
                            <i
                              className={'fa fa-square'}
                              style={{ color: statusColors[key] }}
                            />
                          </span>
                          {status}
                        </td>
                        <td className="text-right">
                          {statusProject.counts[key]}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            <div className="text-center">
              <h6>{t(`This user don't have projects`)}</h6>
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(ReportModal)
);
